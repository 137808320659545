import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { Helmet } from "react-helmet";

const AmlKycPage = ({ location }) => {
  return (
    <Layout>
      <Helmet>
        <title>Anti Money Laundering and KYC Policy | HydraChain</title>
        <link rel="canonical" href={`https://hydrachain.org/${location.pathname}`} />
      </Helmet>
      <Navbar />
      <PageBanner pageTitle="AML/KYC Policy" homePageText="Home" homePageUrl="/" activePageText="AML/KYC Policy" />
      <section className="privacy-policy-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="privacy-policy-content">
                <h2 dir="ltr">Anti-money Laundering, Counter-terrorist Financing &amp; Eligibility Policy</h2>
                <br />
                <p dir="ltr">
                  <b>Introduction</b>
                </p>
                <p dir="ltr">
                  LockTrip LLC, with UIC 204752244, having its seat and management address at Sofia, post code 1799, Bulgaria, Mladost District, 78 Alexander Malinov Blrd., hereinafter as LockTrip, is not among the obliged entities which, according to the applicable European legislation, should
                  apply the measures for prevention of the use of the financial system for the purposes of money laundering and terrorist financing regarding its clients.
                </p>
                <p dir="ltr">
                  Notwithstanding the above mentioned, for the purposes of the legitimate interests pursued by LockTrip to prevent its activity to be involved in any kind of illegal activity including but not limited to money laundering and terrorist financing as well as the public interests for
                  prevention of abuses, LockTrip will implement effective Anti-money Laundering /AML/ and Counter-terrorist Financing /CFT/ internal procedures and mechanisms.
                </p>
                <p dir="ltr">One of the international standards for preventing illegal activity is customer due diligence /CDD/. According to the CDD, LockTrip establishes its own verification procedures within the standards of AML and KYC frameworks.</p>
                <p dir="ltr">
                  <b>1. Identification</b>
                </p>
                <p dir="ltr">LockTrip’s identity verification procedure requires the User to provide LockTrip with national ID or international passport, whose validity has not expired.</p>
                <p dir="ltr">LockTrip reserves the right to collect User’s identification information for the AML/KYC Policy purposes.</p>
                <p dir="ltr">In order to identify individuals, the following data are collected:</p>
                <ul>
                  <li dir="ltr">
                    <p dir="ltr">the names of the User;</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">the date and place of birth;</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">an official personal identification number or other unique element for establishing the identity, contained in an official identity document, the term of validity of which has not expired and on which there is a photo of the client;</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">any citizenship that the person possesses;</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">country of permanent residence and address (mailbox number is not sufficient).</p>
                  </li>
                </ul>
                <br />
                <p dir="ltr">In connection with the above identification, LockTrip will collect copy of the presented identity document.</p>
                <p dir="ltr">User’s identification information will be processed strictly and in accordance with LockTrip’s Privacy Policy and applicable regulations.</p>
                <p dir="ltr">
                  <b>2. High risk third countries</b>
                </p>
                <p dir="ltr">
                  LockTrip accepts that certain third countries have been identified by the European Commission as having strategic weaknesses in AML measures and legislation and these third countries are listed in Commission Delegated Regulation (EU) 2020/855<sup>1</sup>. In this regard LockTrip
                  has the right not to establish business relationship with Users, who are residents or citizens of these high-risk third countries which have provided a written high-level political commitment to address the identified deficiencies and have developed an action plan with FATF.
                </p>
                <p dir="ltr">
                  <b>3. Eligibility</b>
                </p>
                <p dir="ltr">LockTrip may deny services to the users, who are residents or citizens of other third countries such as USA, China and Canada due to the threat of imposing regulatory or other sanctions to LockTrip.</p>
                <p dir="ltr">
                  <b>4. Politically exposed person /PEPs/ and affiliates</b>
                </p>
                <p dir="ltr">
                  LockTrip accepts that there is an increased risk of entering into business relations with individual who is or has been entrusted with a prominent public function /PEPs/, family members of PEPs and also individuals who are closely connected to PEPs, either socially or
                  professionally /close associates /.
                </p>
                <p dir="ltr">In this regard LockTrip will require from the Users to declare if they are or have been PEPs or family members of PEP or close relatives of PEP.</p>
                <p dir="ltr">
                  <b>5. Identity verification</b>
                </p>
                <p dir="ltr">
                  LockTrip may take steps to confirm the authenticity of documents and information provided by the Users. All legal methods for double-checking identification information will be used and LockTrip reserves the right to investigate certain Users who have been determined to be risky or
                  suspicious.
                </p>
                <p dir="ltr">
                  LockTrip reserves the right to verify User’s identity in an on-going basis, especially when their identification information has been changed or their activity seemed to be suspicious (unusual for the particular User). In addition, LockTrip reserves the right to request up-to-date
                  documents from the Users, even though they have passed identity verification in the past.
                </p>
                <p dir="ltr">
                  <b>6. CFT verification</b>
                </p>
                <p dir="ltr">
                  LockTrip will not provide services to Users who have been identified by the competent authorities as having access to terrorism or its financing or who have been subject to sanctions for terrorism or terrorist financing. Therefore, LockTrip will check the Users in lists of European
                  and global counter-terrorism organizations.
                </p>
                <p dir="ltr">
                  <b>7. Origin of funds</b>
                </p>
                <p dir="ltr">LockTrip will provide services only to Users who can prove that their origin of funds is from a legal source. In this regard, Users will be required to declare the origin of funds used in the business relationships.</p>
                <br />
                <p dir="ltr">This Policy was last updated on 7th March 2021. We may change its contents by updating this page to reflect changes in applicable legislation or LockTrip KYC practices.</p>
                <br />
                <small>
                  <sup>1</sup> Commission Delegated Regulation (EU) 2020/855 of 7 May 2020 amending Delegated Regulation (EU) 2016/1675 supplementing Directive (EU) 2015/849 of the European Parliament and of the Council, as regards adding the Bahamas, Barbados, Botswana, Cambodia, Ghana, Jamaica,
                  Mauritius, Mongolia, Myanmar/Burma, Nicaragua, Panama and Zimbabwe to the table in point I of the Annex and deleting Bosnia-Herzegovina, Ethiopia, Guyana, Lao People’s Democratic Republic, Sri Lanka and Tunisia from this table
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default AmlKycPage;
